<template>
    <div>
        <div class="pdfok" id="pdfok" v-if="pdfok"></div>
        <div id="printMe" style=" width: 21cm; margin: 0 auto;">
            <innerPage v-if="dataover" :stuInfo="formData" :type="1"></innerPage>
        </div>
    </div>
</template>

<script>
import innerPage from './com_evaluation/innerPage'
// Load the full build.
var _ = require('lodash');

export default {
    name: 'export',
    components: {
        innerPage,
    },
    data() {
        return {
            pdfok: false,
            dataover: true,
            dataover1: false,
            printObj: {
                id: 'printMe',
                popTitle: '',
                extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>'
            },
            userid: '',
            cover: {},

            stuInfo: {},
            yuebao: [{}],
            zhoubao: [{}],
            kemu: [],
            formData: {},
        }
    },
    mounted() {
        let stu_id = this.getParams('id')
        let pmid = this.getParams("pmid");
        this.getData(stu_id, pmid)
    },
    methods: {
        getData(stu_id, pmid) {
            this.$http.post('/api/init_wxjd_appraise_pdf', { stu_id: stu_id, pmid: pmid }).then(res => {
                let info = (res && res.data) ? JSON.parse(JSON.stringify(res.data)) : {}
                if (info.identify_content) {
                    let pos = info.identify_content.indexOf("\n");
                    console.log('pospospos', pos)
                    let arr = info.identify_content.split("\n")
                    arr = arr.filter(function (s) {
                        return s && s.trim();
                    })
                    info.contentArr = arr
                    console.log('arrarrarr', arr)
                }
                if (info.identify_date) {
                    info.identify_date = this.formatshijianchuo(info.identify_date)
                }
                this.formData = info
                this.$nextTick(() => {
                    this.pdfok = true

                })
            })
        },
        formatshijianchuo(date) {
            //shijianchuo是整数，否则要parseInt转换
            let time = new Date(date)
            var y = time.getFullYear();
            var m = time.getMonth() + 1;
            var d = time.getDate();
            var h = time.getHours();
            var mm = time.getMinutes();
            var s = time.getSeconds();
            return y + '年' + this.timeAdd0(m) + '月' + this.timeAdd0(d) + '日';
        },
        timeAdd0(str) {
            if (str.length <= 1) {
                str = `0${str}`;
            }
            return str
        },
        getParams(name) {
            let n_url = window.location.href
            n_url = n_url.replace('/#', '')
            if (n_url.indexOf('?') > 0) {
                n_url = n_url.split('?')[1]
            }
            var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
            var r = n_url.match(reg)
            if (r != null) return unescape(r[2])
            return null
        },
        //分割数组 arr：原数组  num：分割后每个数组里的数据条数
        util_spliceArrByN(arr, num) {
            var _arr = []
            while (arr.length) {
                _arr.push(arr.splice(0, num))
            }
            return _arr
        },
        //数组按某属性分组
        groupBy(array, f) {
            let groups = {}
            array.forEach(function (o) {
                let group = JSON.stringify(f(o))
                groups[group] = groups[group] || []
                groups[group].push(o)
            })
            return Object.keys(groups).map(function (group) {
                return groups[group]
            })
        }
    }
}
</script>
<style lang="less" type="text/less">
@import "/public/css/pdf_default";
</style>
